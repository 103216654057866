.terminalOutput {
    position: relative;
    height: calc(100vh - 200px);
    min-height: 10em;
    padding: 0;
    margin: 0;
}

.terminalButtons {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background: rgba(32, 33, 36, .6);
    color: #fff;
}

.terminalButtons>button {
    color: #fff !important;
}

.terminalButtons>button.Mui-disabled {
    color: #aaa !important;
}

.terminalButtons>button.MuiButtonGroup-groupedTextHorizontal:not(:last-child) {
    border-color: #777 !important;
}

.codeContainer {
    height: 100%;
    width: 100%;
    background: #202124;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    display: flex;
    overflow-x: scroll;
    overflow-y: scroll;
    flex-direction: column-reverse;
    resize: vertical;
}

.codeContainer::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.codeContainer::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

.codeContainer::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 4px;
}

.codeContainer::-webkit-scrollbar-corner {
    background: transparent;
    border-radius: 4px;
}

.codeContainer::-webkit-resizer {
    display: none;
}


code {
    margin: .5rem;
    padding: 0;
    font-family: Roboto Mono, monospace;
    font-weight: 300;
}

code>p {
    padding: 0;
    margin: 0;
}

span.time {
    font-weight: 300;
    color: #aaa;
    width: 100%;
}

span.output {
    color: #fff;
}

span.userInput {
    color: #fbbc05;
    font-weight: 700;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid;
}