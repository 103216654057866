
body {
  //background-color: #edf6f5!important;
  //background: #f7f7f7;
  background: #f7f8fc;
  font-family: urbanist,sans-serif;
  font-weight: 400;
  line-height: 1.75;
  //color: #777;
}

.head-3 {
  font-size: 1.2rem;
  font-weight: normal;
}

.cl-light {
  //color: #999;
  color: #666;
}

.relative {
  position: relative;
}

p, .paragraph {
  font-weight: 400;
  color: #777;
  font-size: 16px;
  line-height: 1.7;
  font-family: urbanist,sans-serif;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: urbanist,sans-serif;
  font-weight: 700;
  color: #222;
}

.main-container {
  @include breakpoint(min-width 768px) {
    max-width: 768px;
    //max-width: 100%;
  }
  @include breakpoint(min-width 992px) {
    //max-width: 768px;
    max-width: 100%;
  }
  @include breakpoint(min-width 1024px) {
    max-width: 100%;
  }
  @include breakpoint(min-width 1280px) {
    max-width: 1280px;
  }
  margin: 0 auto;
  width: 100%;
}
