@import "vars";
@import "breakpoint";

$cardcol: 80px;
$cardcolsm: 60px;

.dash {
  padding: 25px 15px;
  //max-width: 1024px;
  //margin: 0 auto;

  @include breakpoint($tablet) {
    padding: 15px;
  }

  .card-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    //grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @include breakpoint($tablet) {
      grid-template-columns: repeat(6, 1fr);
      gap: 14px;
    }
    .card {
      grid-column: span 6;
      &.card-single {
        grid-column: span 2;
        padding-right: 0;

        @include breakpoint($tablet) {
          grid-column: span 6;
        }
      }
      &.card-dual {
        grid-column: span 4;
        padding-right: 0;

        @include breakpoint($tablet) {
          grid-column: span 6;
        }
      }
    }
  }

  .info-right-switch {
    display: flex;
    /* justify-items: center; */
    align-items: center;
    height: 100%;
    //min-width: 300px;
    padding: 20px;
    justify-content: center;
    max-width: 300px;
    > .switch-wrap {
      background: #0aa8a720;
      border-radius: 10px;
      padding: 10px;
    }
  }

  .card {
    position: relative;
    padding: 10px 20px;
    //background: #f7f7f7;
    background: #fff;
    //border: 1px solid #bfc3c4;
    //border: 1px solid #bbb;

    //border: 1px solid #e2e8ec;
    //box-shadow: 0 0 6px 0 rgba(0,0,0,.12);

    $card-box-shadow: 0 0 2px rgba(0,0,0,0.05), 0 0 3px rgba(0,0,0,0.05);
    box-shadow: $card-box-shadow;

    //border-radius: 4px;
    border-radius: 6px;
    align-items: center;
    //margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    box-sizing: content-box;
    min-height: 80px;

    .card-dot {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    &.device-info {
      //padding: 20px 36px;
      padding: 20px 12px 20px 35px;

      .info-holder {
        margin-left: 32px;
      }
    }

    &.card-status, &.card-grid {
      height: $cardcol *2 + 10px;
      @include breakpoint($tablet) {
        height: $cardcolsm *2 + 10px;
      }
    }

    .img-holder {
      width: 56px;
      height: 56px;
      background: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      //border-radius: 100%;
      border-radius: 6px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .info-title {
      font-size: 1.1rem;
      font-weight: 600;
      color: #555;
      @include breakpoint($tablet) {
        font-size: 1rem;
      }
    }

    .info-value {
      font-size: 1.4rem;
      font-weight: 600;
      color: #555;
      position: relative;
      height: 33.6px;
      .info-unit {
        //font-size: 1.2rem;
        font-size: 1.1rem;
      }
    }

    .info-holder {
      flex: 1;
      margin-left: 16px;
    }

    .dev-pn {
      font-size: 1.1rem;
      color: #555;
      > span {
        font-weight: 600;
        color: $primary;
      }
    }

    &.card-grid {
      //padding: 10px 10px 10px 0;
      padding: 10px 12px 10px 0;

      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-auto-rows: minmax($cardcol, auto);
      //grid-template-rows: repeat(5, 2fr 5fr);
      grid-template-areas:
    'img img chr chr chr chr'
    'inf inf chr chr chr chr';

      //margin-bottom: 10px;

      .grid-right {
        grid-area: chr;
        height: $cardcol *2;
        border-radius: 4px;
      }
      .grid-edit {
        grid-area: edt;
      }

      @include breakpoint($tablet) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas:
    'img img img chr chr chr chr chr'
    'inf inf inf chr chr chr chr chr';
        grid-auto-rows: minmax($cardcolsm, auto);
        .grid-right {
          height: $cardcolsm *2;
        }
      }

      &.card-single {
        //background: transparent;
        //box-shadow: none;
        grid-template-columns: repeat(2, 1fr);

        grid-template-areas:
    'img img'
    'inf inf';
        //width: 3/8;
        //grid-column: span 1
      }

      &.card-dual {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          'img img'
          'inf inf';
        &.card-dual-edit {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            'img edt'
            'inf edt';
          @include breakpoint($tablet) {
            grid-template-columns: repeat(8, 1fr);
            grid-template-areas:
            'img img img edt edt edt edt edt'
            'inf inf inf edt edt edt edt edt';
          }
          .card-dot button {
            color: green!important;
            background: rgba(122, 237, 166, 0.1);
            &:hover {
              background: rgba(122, 237, 166, 0.204);
            }
          }
        }
      }

      &.card-single-fill {
        grid-template-columns: repeat(2, 1fr);

        grid-template-areas:
    'img img'
    'inf inf';
      }

      .info-img {
        grid-area: img;

        display: flex;
        justify-content: center;
        align-items: center;
      }
      .grid-left {
        grid-area: inf;

        //display: flex;
        //justify-content: center;
        //align-items: center;
        text-align: center;
      }
    }

    .info-center {
      max-width: 200px;
      justify-content: center;
      align-items: center;

      height: $cardcol *2;

      .info-left > * {
        justify-content: center;
        display: flex;
      }
    }

    .info-data, .card-data {
      display: flex;
      font-size: 1rem;
      //font-size: 0.9rem;
      color: #444;

      @include breakpoint($tablet) {
        display: block;
        color: #555;
        //font-size: 0.85rem;
        font-size: 0.9rem;
      }

    }
    .info-left,
    .info-right {
      flex: 1;
    }

    &.card-ident {
      padding-top: 20px;
      padding-bottom: 0;
      @include breakpoint(max-width $tablet) {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .img-holder {
        width: 64px;
        height: 64px;
        color: #216ce0;
        background: transparent;
        svg {
          width: 64px;
          height: 64px;
        }
      }
      .info-holder {
        margin-left: 0!important;
      }
      .dev-name {
        margin-bottom: 0.6rem;
        @include breakpoint(max-width $tablet) {
          margin-bottom: 0.2rem;
        }
        b {
          font-size: 1.3rem;
          font-weight: 600;
          color: #555;
        }
      }
      .grid-right {
        height: auto !important;
        min-height: $cardcol *2;
        @include breakpoint(min-width $tablet) {
          padding-bottom: 10px;
        }
      }
      @include breakpoint(max-width $tablet) {
        height: auto;
      }
      @include breakpoint(min-width $tablet) {
        grid-column: span 8;
        grid-template-areas:
    'img img chr chr  chr chr chr chr '
    'inf inf chr chr  chr chr chr chr ';
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }

  .device-info {
    max-width: 900px;
    .info-data {
      font-size: 1.1rem;
    }
  }
}

.chart-wrap {
  //padding-left: 20px;
  padding-left: 10px;
  //margin-right: -10px;
  padding-top: 30px;
  overflow: hidden;
  height: 400px;
  width: 100%;
  @include breakpoint($tablet) {
    height: 300px;
  }

  tspan {
    font-size: 0.8rem;
    @include breakpoint($tablet) {
      font-size: 0.7rem;
    }
  }

  > .recharts-responsive-container {
    //width: calc(100% + 20px);
    width: calc(100% + 20px)!important;
    @include breakpoint($tablet) {
      width: calc(100% + 30px)!important;
      //padding-top: 15px;
    }
  }
}

//.recharts-wrapper {
.chart-holder {
  padding-top: 20px;
}