
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$mobile: map-get($grid-breakpoints, "sm");
$tablet-portrait: map-get($grid-breakpoints, "md");
$tablet: map-get($grid-breakpoints, "lg");
$desktop: map-get($grid-breakpoints, "xl");
$desktop-wide: map-get($grid-breakpoints, "xxl");

$primary: #0aa8a7;
$primary: #2674fc;
$primary: #018cef;
$primary: #216ce0;

$primary1: #216ce0;
$primary-hover: #0c4db3;
$primary2: #216ce0;

$font: urbanist,sans-serif;

//.btn {
//
//  margin: 0 5px;
//  border-radius: 4px;
//}

