
.recharts-responsive-container {
  position: relative;
}

.chart-labels {
  font-size: 0.7rem;
  font-weight: bold;
  //position: relative;
  .chart-time {
    position: absolute;
    bottom: 2px;
    right: 10px;
    z-index: 1;
  }
}

.chart-minmax {
  //position: absolute;
  bottom: 2px;
  left: 10px;
  z-index: 1;
  .chart-max {
    margin-right: 12px;
  }
  .chart-time,
  .chart-min,
  .chart-max {
    cursor: default;
    //display: block;
  }
}
.recharts-curve.recharts-tooltip-cursor {
  display: none;
}
