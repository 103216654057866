@import "vars";

// Examples:
// @include breakpoint(456px) -> @media only screen and (max-width: 456px)
// @include breakpoint(min-width 456px) -> @media only screen and (min-width: 456px)
// @include breakpoint($mobile) -> @media only screen and (max-width: $mobile)
// @include breakpoint(min-width $mobile) -> @media only screen and (min-width: $mobile + 1)
// @include breakpoint(123px, 248px) -> @media only screen and (min-width: 123px) and (max-width: 248px)
// @include breakpoint(max-width 248px, min-width 123px) -> @media only screen and (max-width: 248px) and (min-width 123px)

@mixin breakpoint($point, $point2: null) {
  $media: max-width;
  $media2: null;
  @if $point2 != null {
    $media2: max-width;
    @if type-of($point2) == list and length($point2) == 2 {
      $media2: nth($point2, 1);
      $point2: nth($point2, 2);
    }
    @if $media2 == max-width {
      $media: min-width;
    }
    @if $media2 == min-width {
      @if $point2 == $mobile or $point2 == $tablet-portrait or $point2 == $tablet {
        $point2: $point2 + 1;
      }
      @else if map_has_key($grid-breakpoints, $point2) {
        $point2: map-get($grid-breakpoints, $point2)
      }
    }
  }
  @if type-of($point) == list and length($point) == 2 {
    $media: nth($point, 1);
    $point: nth($point, 2);
  }
  @if $media == min-width {
    @if $point == $mobile or $point == $tablet-portrait or $point == $tablet {
      $point: $point + 1;
    }
    @else if map_has_key($grid-breakpoints, $point) {
      $point: map-get($grid-breakpoints, $point)
    }
  }
  @if not $media2 {
    @media only screen and ($media: $point) {
      @content;
    }
  }
  @else {
    @media only screen and ($media: $point) and ($media2: $point2) {
      @content;
    }
  }
}
