
body #primary-btn {
  font-size: 1.2rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 120px;
  padding: 10px 30px;
  border-radius: 4px;
  font-family: $font;

  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  //background: #1166ee;
  //background: #891dff;
  border-radius: 6px;
  height: 60px;
  margin-top: -30px;
  //&:hover {
  //  background: #891dff;
  //}
}

.home {
  .connect-info {
    font-size: 1.1rem;
    font-family: $font;
    position: relative;
    margin: 0 auto;
    width: 300px;
    overflow: visible;
    height: 1px;
    > div {
      position: absolute;
      z-index: 20;
      color: #333;
      text-align: center;
      height: 60px;
      /* top: 0; */
      left: 0;
      right: 0;
      bottom: 150px;
    }
  }
}

$size: 560px;
$step: 48px;

.connect-btn-center-wrap {
  width: 100vw;
  overflow: hidden;
}

.connect-btn-center {
  width: calc(100vw + #{$size}) ;
  margin-left: - $size / 2;
}


.connect-btn-wrap {
  margin: 0 auto;
  width: $size;
  height: $size;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    inset: $step;
    background: #f5f4f8;
    border-radius: 100%;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    inset: $step * 2;
    background: #e9e1f7;
    border-radius: 100%;
    z-index: 2;
  }

  .connect-btn-container {
    width: $size;
    height: $size;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: $step * 3;
      background: #e6d6fb;
      border-radius: 100%;
      z-index: 3;
    }
    &:after {
      content: "";
      position: absolute;
      inset: $step *4;
      background: #e3ccfe;
      border-radius: 100%;
      z-index: 4;
    }
    //&:after {
    //  content: "";
    //  position: absolute;
    //  inset: $step * 5;
    //  background: #e9e1f7;
    //  border-radius: 100%;
    //  z-index: 1;
    //}
  }
}
