
.battery-card {
  .grid-edit {
    //background: rgba(0,0,0,0.1);
    //padding-right: 30px;
    .form-control {
      max-width: 160px;
      //background: rgba(0,0,255,0.1);
      margin: 10px 0;
      label {
        //font-weight: 400;
        font-size: 0.8rem;
        width: 30px;
        display: inline-block;

      }
      .rc-input-number {
      //  font-size: 1rem;
      //  height: 32px;
      }

    }
  }
  .info-value {
    overflow: hidden;
  }
}

.rc-input-number-handler {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    //color: currentColor;
    margin-top: -1px;
  }
}
.rc-input-number-input {
  font-weight: 600;
  font-size: 1.1rem;
}

//.rc-input-number-handler-wrap {
//  width: 24px;
//}
