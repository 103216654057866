@import "vars";
@import "breakpoint";

.app-navbar-menu {

}

.app-toolbar {
  justify-content: space-between;
}

.app-navbar-menu-wrap {
  position: relative;
}

.logo {
  height: 30px;
}

.header-menu-li {
  color: #333;
  display: flex;
  height: 50px;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  align-items: center;
  > a, > span {
    display: flex;
    align-items: center;
  }
  &:hover {

    background: #F2F2F2;
  }
}

.app-nav-dropdown {
  opacity: 1;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
  position: absolute;
  z-index: 100;
  margin-top: 8px;
  left: initial!important;

  @include breakpoint($mobile) {
    margin-top: 4px;
  }

  @include breakpoint($tablet-portrait) {
    border-radius: 0;
    position: fixed!important;
    left: 0!important;
  }
  @include breakpoint(min-width $tablet-portrait) {
    min-width: 200px;
    margin-top: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.15), 0 0 3px rgba(0,0,0,0.1);
  }

  .icon {
    margin-right: 10px;
  }
}
