@import "vars";
@import "breakpoint";

$fnt_sm: 0.8rem;
$fnt_sm2: 0.7rem;

.heatmap-heatval-8 {
  background: #d73027;
}

.heatmap-heatval-7 {
  background: #f46d43;
}

.heatmap-heatval-6 {
  background: #fee08b;
}

.heatmap-heatval-5 {
  background: #ffffbf;
}

.heatmap-heatval-4 {
  background: #d9ef8b;
}

.heatmap-heatval-3 {
  background: #a6d96a;
}

.heatmap-heatval-2 {
  background: #66bd63;
}

.heatmap-heatval-1 {
  background: #1a9850;
}


.card-grid-wrapper .heatmap {
  grid-column: span 6;
  @include breakpoint(max-width $tablet) {
    grid-column: span 12;
  }
}

.heatmap {
  margin: 20px 0;
  max-width: 748px;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  @include breakpoint(max-width $tablet) {
    padding: 10px 6px;
  }

  &.heatmap-size-5 {
    .heatmap-head,
    .heatmap-row {
      display: grid;
      grid-template-columns: repeat(13, 1fr);
      @include breakpoint(max-width $tablet) {
        grid-template-columns: repeat(5, 1fr);
      }
      gap: 1px;
      margin-bottom: 1px;
    }
    @include breakpoint(max-width $tablet) {
      .heatmap-title,
      .heatmap-time {
        grid-column: span 5;
      }
    }
  }

  &.heatmap-size-8 {
    max-width: initial;
    width: 100%;
    .heatmap-head,
    .heatmap-row {
      display: grid;
      grid-template-columns: repeat(19, 1fr);
      @include breakpoint(max-width $tablet) {
        //grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(8, 1fr);
      }
      gap: 1px;
      margin-bottom: 1px;
    }
    @include breakpoint(max-width $tablet) {
      .heatmap-title,
      .heatmap-time {
        //grid-column: span 4;
        grid-column: span 8;
      }
    }
  }

  //background: red!important;
  .heatmap-title {
    font-weight: bold;
    //align-items: center;
    justify-content: center;
  }
  .heatmap-time {
    font-size: 0.9rem;
    text-align: center;
    justify-content: center;
  }
  .heatmap-title,
  .heatmap-time {
    grid-column: span 3;
    display: flex;
    align-items: center;
    @include breakpoint(max-width $tablet) {
      align-items: center;
      justify-content: center;
    }
  }
  .heatmap-time {
    //background: #e5e5ef;
    background: #f2f2f2;
  }
  .heatmap-hd,
  .heatmap-col {
    grid-column: span 2;
    @include breakpoint(max-width $tablet) {
      grid-column: span 1;
    }
  }
  .heatmap-hd {
    //text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //grid-auto-rows: minmax($cardcol, auto);
    grid-template-areas:
    'name name'
    'low high';
    gap: 1px;
    //background: #efeff4;
    background: #f2f2f2;
    @include breakpoint(max-width $tablet) {
      padding: 2px 5px;
      .heatmap-hd-low,
      .heatmap-hd-high {
        display: none;
      }
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .heatmap-hd-name {
      grid-area: name;
      justify-content: center;
      text-align: center;
      font-size: 0.9rem;
      @include breakpoint(max-width $tablet) {
        font-size: 0.8rem;
        writing-mode: vertical-lr;
      }
    }
    .heatmap-hd-low {
      grid-area: low;
      font-size: $fnt_sm;
    }
    .heatmap-hd-high {
      grid-area: high;
      font-size: $fnt_sm;
    }
  }

  .heatmap-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    align-items: end;
    .heatmap-col-val {
      font-size: 0.9rem;
      text-overflow: clip;
      white-space: nowrap;
      .heatmap-unit {
        font-size: 0.8rem;
      }
    }
    .heatmap-col-low,
    .heatmap-col-high {
      display: grid;
      grid-template-columns: 1fr;
      //background: #555;
      padding-bottom: 2px;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @include breakpoint(max-width $tablet) {
      //grid-column: span 1;
      grid-template-columns: 1fr;
      .heatmap-col-val {
        font-size: 0.8rem;
        text-overflow: clip;
        white-space: nowrap;
        align-items: end!important;
        .heatmap-unit {
          font-size: 0.75rem;
        }
      }
    }
    .heatmap-col-time {
      font-size: $fnt_sm2;
      @include breakpoint(max-width $tablet) {
        font-size: $fnt_sm2 * 0.8;
      }
    }
  }
}