
.unsupported {
  width: 620px;
  @include breakpoint(max-width 620px) {
    width:  auto;
  }
  //background: rgba(0,0,0,0.2);
  text-align: center;
  padding: 0 14px;
  h3 {
    font-size: 2.2rem;
    @include breakpoint(max-width $mobile) {
      font-size: 2rem;
    }
    margin-bottom: 15px;
  }
  p {
    font-size: 0.9rem;
    font-weight: 600;
    //b {
    //  font-weight: bold;
    //  color: #000;
    //}
  }
  .browsers {
    //background: rgba(0,0,255,0.2);

    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    @include breakpoint(max-width $mobile) {
      margin-top: 30px;
    }
    svg {
      font-size: 130px;
    }
    @include breakpoint(max-width $mobile) {
      gap: 10px;
      svg {
        font-size: 80px;
      }
    }
  }
  .browser {
    text-align: center;
    flex: 1;

    .browser-link,
    .browser-img {
      display: block;
    }
    .browser-link {
      margin-top: 30px;
      @include breakpoint(max-width $mobile) {
        margin-top: 20px;
      }
      b, span {
        display: block;
        line-height: 1rem;
      }
      b {
        font-size: 0.9rem;
        //color: $primary1;
      }
      span {
        font-size: 0.7rem;
        color: #666;
      }
      &:hover {
        b {
          text-decoration: underline;
          //color: $primary-hover;
        }
      }
    }
  }
  .more {
    font-size: 0.8rem;
    //color: $primary1;
    &:hover {
      text-decoration: underline;
      //color: $primary-hover;
    }
  }
}

.home {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: calc(100vh - 160px);
  min-height: 10em;
}
